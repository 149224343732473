'use client'
import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/navigation';
import { useTranslations } from 'next-intl';
import Image from 'next/image';

import Table from '../Table/Table';

import { Header, StatType, Type } from '@/types/types';



const TopLeaguesSection = () => {
  const [topLeagues, setTopLeagues] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const router = useRouter()

  const tTable = useTranslations('table');

  useEffect(() => {
    const fetchTopLeagues = async () => {
      try {
        const res = await fetch(`${process.env.NEXT_PUBLIC_SITE_URL}/api/leagues/top`, {
          next: { revalidate: 3600 },
        });
        if (!res.ok) throw new Error('Failed to fetch top leagues');

        const data = await res.json();
        setTopLeagues(data);
        setLoading(false)
      } catch (error) {
        console.error('Error fetching top leagues:', error);
      }
    };

    fetchTopLeagues();
  }, []);

  

  const handleRowClick = (leagueId: number, statType: StatType, type: Type, threshold: number) => {
    router.push(`/league/${leagueId}/${statType}?type=${type}&threshold=${threshold}`);
  };

  const headers: Header[] = [
    { label: <span key="country"></span> },
    { label: <span key="logo"></span> },
    { label: <span key="name">League</span>, sortKey: 'name' },
    {
      label: 
        <div className="text-center">
          <Image
            className=""
            src="/icons/check-icon.png"
            alt="Matched Fixtures"
            width={22}
            height={22}
          />
        </div>,
      sortKey: "matchedFixtures",
    },
    { label: (
      <div className="text-center">
        <Image
          className="d-none logo-dark"
          src="/icons/fixtures-light-icon.svg"
          alt="Total Fixtures"
          width={22}
          height={22}
        />
        <Image
          className="d-none logo-light"
          src="/icons/fixtures-dark-icon.svg"
          alt="Total Fixtures"
          width={22}
          height={22}
        />
      </div>
    ), sortKey: "totalFixtures" },
    { label: (
      <div className="text-center">
        <Image
          className="d-none logo-dark"
          src="/icons/percentage-light-icon.svg"
          alt="Percentage"
          width={19}
          height={19}
        />
        <Image
          className="d-none logo-light"
          src="/icons/percentage-dark-icon.svg"
          alt="Percentage"
          width={18}
          height={18}
        />
      </div>
    ), sortKey: "percentage" }
  ]

  const generateRows = (leagues: any[], statType: StatType, type: Type, threshold: number) =>
    leagues.map((league, index) => (
      <React.Fragment key={index}>
        <td className='px-1' onClick={() => handleRowClick(league.id, statType, type, threshold)}>
          <Image
            src={league.countryFlag}
            alt={league.countryName}
            width={18}
            height={18}
          />
        </td>
        <td className='px-1' onClick={() => handleRowClick(league.id, statType, type, threshold)}>
          <Image
            src={league.logo}
            alt={league.name}
            width={18}
            height={18}
          />
        </td>
        <td className="px-1 w-100" onClick={() => handleRowClick(league.id, statType, type, threshold)}>{league.name}</td>
        <td className="px-1" onClick={() => handleRowClick(league.id, statType, type, threshold)}>{league.matchedFixtures}</td>
        <td className="px-1" onClick={() => handleRowClick(league.id, statType, type, threshold)}>{league.totalFixtures}</td>
        <td className="px-1" onClick={() => handleRowClick(league.id, statType, type, threshold)}>{league.percentage}</td>
      </React.Fragment>
    ));

  const overGoalsRows = generateRows(topLeagues.goalsOver2 || [], "goals", "over", 2.5);
  const underGoalsRows = generateRows(topLeagues.goalsUnder3 || [], "goals", "under", 2.5);
  const cardsOverRows = generateRows(topLeagues.cardsOver4 || [], "cards", "over", 4.5);
  const cardsUnderRows = generateRows(topLeagues.cardsUnder5 || [], "cards", "under", 4.5);
  const cornersOverRows = generateRows(topLeagues.cornersOver9 || [], "corners", "over", 9.5);
  const cornersUnderRows = generateRows(topLeagues.cornersUnder10 || [], "corners", "under", 9.5);

  return (
    <>
      <h2 className='text-center my-4 my-lg-5 slide-in-left'>Top Leagues on...</h2>
      <div className="d-flex flex-wrap">
        {topLeagues.goalsOver2 && <div className='col-12 col-xl-6 my-2'>
          <h5 className='text-center'>Over 2.5 Goals</h5>
          <div className='slide-in-left'>
            <Table
              headers={headers}
              rows={overGoalsRows}
              sortBy='percentage'
              sortDirection='desc'
              loading={loading} 
            />
          </div>
        </div>}
        {topLeagues.goalsUnder3 && <div className='col-12 col-xl-6 my-2'>
          <h5 className='text-center'>Under 2.5 Goals</h5>
          <div className='slide-in-right'>
            <Table
              headers={headers}
              rows={underGoalsRows}
              sortBy='percentage'
              sortDirection='desc'
              loading={loading} 
            />
          </div>
        </div>}
        {topLeagues.cardsOver4 && <div className='col-12 col-xl-6 my-2'>
          <h5 className='text-center'>Over 9.5 Corners</h5>
          <div className='slide-in-left'>
            <Table
              headers={headers}
              rows={cornersOverRows}
              sortBy='percentage'
              sortDirection='desc'
              loading={loading} 
            />
          </div>
        </div>}
        {topLeagues.cardsUnder5 && <div className='col-12 col-xl-6 my-2'>
          <h5 className='text-center'>Under 9.5 Corners</h5>
          <div className='slide-in-right'>
            <Table
              headers={headers}
              rows={cornersUnderRows}
              sortBy='percentage'
              sortDirection='desc'
              loading={loading} 
            />
          </div>
        </div>}
        {topLeagues.cornersOver9 && <div className='col-12 col-xl-6 my-2'>
          <h5 className='text-center'>Over 4.5 Cards</h5>
          <div className='slide-in-left'>
            <Table
              headers={headers}
              rows={cardsOverRows}
              sortBy='percentage'
              sortDirection='desc'
              loading={loading} 
            />
          </div>
        </div>}
        {topLeagues.cornersUnder10 && <div className='col-12 col-xl-6 my-2'>
          <h5 className='text-center'>Under 4.5 Cards</h5>
          <div className='slide-in-right'>
            <Table
              headers={headers}
              rows={cardsUnderRows}
              sortBy='percentage'
              sortDirection='desc'
              loading={loading} 
            />
          </div>
        </div>}
      </div>
      <p className='text-end me-2 slide-in-right'>Leagues with more than 1 month and more than 5 fixtures played</p>
    </>
  )
};

export default TopLeaguesSection;