'use client'
import React from 'react';
import { useTranslations } from 'next-intl';
import { useParams } from 'next/navigation';


interface TrendProps {
  type: string;
  threshold: number;
  teamRole: string;
  scope: string;
  half: number;
  trend: { 
    percentage: number;
    matchedFixtures: number;
    totalFixtures: number;
  }
}

interface TrendDescriptionProps {
  trend: TrendProps;
}

const TrendDescription: React.FC<TrendDescriptionProps> = ({ trend }) => {
  const { type, threshold, teamRole, scope, half, trend: trendDetails } = trend;
  const { locale } = useParams(); 

  const tCommon = useTranslations('common');
  const tFilter = useTranslations('filter');
  const tTrends = useTranslations('trends');

  let direction = '';
  let stat = '';
  let halfDescription = '';
  let teamRoleDescription = '';
  let scopeDescription;

  if (type.toLowerCase() === "btts") {
    stat = "btts";
  } else if (['draw', 'win', 'loss'].includes(type.toLowerCase())) {
    // direction = type.charAt(0).toUpperCase() + type.slice(1);
    stat = type
  } else {
    const typeParts = type.match(/(under|over)(Team|Opponent|Combined)?(TotalShots|OnShots|Goals|Corners|Cards)/i);
    if (typeParts) {
      direction = typeParts[1].charAt(0).toUpperCase() + typeParts[1].slice(1);
      stat = typeParts[3].charAt(0).toLowerCase() + typeParts[3].slice(1);
    }
  }

  halfDescription =
    half === 1
      ? tFilter('firstHalf')
      : half === 2
      ? tFilter('secondHalf')
      : tFilter('wholeMatch');

  // teamRoleDescription = tFilter(teamRole);

  scopeDescription = (
    <>
      {scope === 'team'
        ? `${tCommon('forThe')} `
        : scope === 'opponent'
        ? `${tCommon('from')} `
        : scope === 'combined'
        ? `${tCommon('forBoth')} `
        : ''}
      <strong>
        {scope === 'team'
          ? tFilter('team')
          : scope === 'opponent'
          ? tFilter('opponents')
          : scope === 'combined'
          ? tCommon('bothTeams')
          : ''}
      </strong>
    </>
  );

  const roleDescription = () => {
    switch(locale) {
      case "es":
        return `${tCommon('matches')} ${teamRole !== "all" ? tCommon('as') + " " + tFilter(teamRole) : ''}`;
      default:
        return `${teamRole !== 'all' ? tFilter(teamRole) + ' ' + tCommon('matches') : tCommon('matches')}`;
    }
  }

  return (
    <span>
      <strong>
        {direction} <strong>{threshold > 0 ? threshold : ''}</strong> {tFilter(stat)}{' '}
      </strong>{' '}
      {scopeDescription} {tCommon('at')} <strong>{halfDescription}</strong>{' '}
      {tCommon('inTheLast')}{' '}
      {trendDetails.percentage !== 100 && (
        <>
          <strong>{trendDetails.matchedFixtures}</strong> {tCommon('of')}{' '}
        </>
      )}
      <strong>{trendDetails.totalFixtures}</strong>{' '}{roleDescription()}
    </span>
  );
};

export default TrendDescription;
