'use client';

import { useState, useEffect } from 'react';

function useWindowSize() {
  // Estado para almacenar el tamaño de la ventana
  const [windowSize, setWindowSize] = useState({
    width: 1200,
    height: 0,
  });

  useEffect(() => {
    // Función para actualizar el estado con el tamaño actual de la ventana
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    if (typeof window !== 'undefined') {
      // Añade el event listener
      window.addEventListener('resize', handleResize);

      // Llama a la función inmediatamente para establecer el tamaño inicial
      handleResize();

      // Limpia el event listener cuando el componente se desmonta
      return () => window.removeEventListener('resize', handleResize);
    }
  }, []); // El array vacío asegura que el efecto solo se ejecute una vez

  return windowSize;
}

export default useWindowSize;
